import { useEffect, useState } from "react";

export default function EntryStatusBadge({entryStatus}) {
    const { count, max, status } = entryStatus;

    return (
        <div className="flex items-center">
            <div className={`${(status === 'open') ? 'text-success' : 'text-error'}`}>{count}/{max}</div>
            <div className={`badge badge-lg uppercase ${(status === 'open') ? 'badge-success' : 'badge-error'} text-white font-bold text-xs ml-2`}>
                {status}
            </div>
        </div>
    );
}
